<template>
    <div class="container">
        <a-row style="padding: 12px 0 0 12px">
            <a-button type="primary" @click="addOrEdite(null)">新增</a-button>
        </a-row>
        <br/>
        <a-table
            :columns="columns"
            :dataSource="data"
            rowKey="departmentSn"
            bordered
            :loading="loading"
            :pagination="false"
            v-if="data.length>0"
            :defaultExpandAllRows="true">
            <template slot="action" slot-scope="text,record">
                <a @click="addOrEdite(text)" v-if="record.classification!==0">编辑</a>
                <a-divider type="vertical" />
                <a-popconfirm
                        title="是否同意删除？"
                        @confirm="remove(text.departmentSn)"
                        okText="是"
                        cancelText="否"
                        placement="topRight"
                >
                    <a class="txt-danger" href="javascript:;" v-if="record.classification!==0">删除</a>
                </a-popconfirm>
            </template>
        </a-table>
        <br/>
        <form-modal
                ref="FormModal"
                :visible="visible"
                @close="handcanle"
                @confirm="handleConfirm"
        />
    </div>
</template>

<script>
    import {columns} from "./module";
    import FormModal from "./module/formModal"
    import {list,del} from '@/api/org'
    export default {
        components:{FormModal},
        data(){
            return {
                visible: false,
                columns,
                formLayout:'inline',
                defaultExpandAllRows: true,
                loading: false,
                searchForm:{},
                data:[]
            }
        },
        created() {
            this.loadList()
        },
        methods:{
            search() {
                this.loadList()
            },
            reset(){
                this.searchForm ={}
                this.load()
            },
            loadList() {
                list(this.searchForm).then((res)=>{
                  const userInfo = localStorage.getItem('userInfo')
                    var departmentSn = JSON.parse(userInfo).departmentSn
                    var departmentName = JSON.parse(userInfo).departmentName
                    var obj = res.filter((res)=>res.departmentSn==departmentSn)
                    obj[0].children =this.convertToTreeData(res, departmentSn)
                    if (res.length > 0) {
                        this.data = obj
                    }
                })
            },
            convertToTreeData(data, pid) {
                const result = [];
                let temp = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].parentSn == pid) {
                        const obj =  data[i]
                        temp = this.convertToTreeData(data, data[i].departmentSn);
                        if (temp.length > 0) {
                            obj["children"] = temp;
                        }
                        result.push(obj);
                    }
                }
                return result;
            },
            onShowSizeChange(current, pageSize) {
                console.log(current, pageSize);
            },
            addOrEdite(row) {
                this.visible = true
                if(row){
                    this.$refs.FormModal.setFormValues(row)
                }
            },
            handcanle() {
                this.visible = false
            },
            handleConfirm() {
                this.loadList()
            },
            remove(id) {
                del({departmentSn:id}).then((res) =>{
                    this.loadList()
                })
            }
        }
    }
</script>

<style scoped>
</style>