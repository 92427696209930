const columns = [
        {
            dataIndex: '',
            key: '',
            fixed: 'left',
            width: 70
        },
        {
            title: '组织名称',
            dataIndex: 'departmentName',
            key: 'departmentName',
        },
        {
            title: '父级组织名称',
            dataIndex: 'departmentParentName',
            key: 'departmentParentName',
            ellipsis: true,
        },
        {
            title: '创建人',
            dataIndex: 'createName',
            key: 'createName',
            ellipsis: true,
        },
        {
            title: '创建时间',
            key: 'createTime',
            dataIndex: 'createTime',
        },
        {
            title: '操作',
            key: 'operation',
            fixed: 'right',
            width: 140,
            scopedSlots: { customRender: 'action' },
        },
    ];
export {
    columns
}
