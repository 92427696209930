<template>
    <a-modal
            :title="departmentSn?'组织修改':'组织新增'"
            :visible="visible"
            width="30%"
            :confirm-loading="confirmLoading"
            ok-text="确认"
            cancel-text="取消"
            @ok="handleConfirm"
            @cancel="handleCancel">
        <a-form :form="form">
            <a-form-item label="组织名称" v-bind="formItemLayout">
                <a-input
                        placeholder="请输入组织名称"
                        v-decorator="['departmentName',
            {rules: [{ required: true,max:50, message: '组织名称必填、最大长度20个字符'}]}]"
                />
            </a-form-item>
            <a-form-item label="所属组织" v-bind="formItemLayout">
                <a-tree-select style="width: 100%"
                               :disabled="departmentSn?true:false"
                               @select="parentChange"
                               v-decorator="['parentSn',{rules: [{ required: true, message: '请选择所属机构' }]}]"
                               :treeData="types"
                               placeholder='请选择所属机构'
                               treeDefaultExpandAll>
                </a-tree-select>
            </a-form-item>
        </a-form>
    </a-modal>
</template>

<script>
    import {list,add,update} from '@/api/org'
    export default {
        props:['visible'],
        data(){
            return {
                form: this.$form.createForm(this),
                formItemLayout: {
                    labelCol: {
                        span: 6
                    },
                    wrapperCol: {
                        span: 18
                    }
                },
                maxLength:1000,
                confirmLoading: false,
                types:[],
                departmentSn:''
            }
        },
        computed: {
            show: {
                get: function (){
                    return this.visible;
                },
                set: function () {}
            }
        },
        watch:{
            visible(val){
                if(val) {
                   this.loadType()
                }
            }
        },
        methods:{
            loadType() {
                list(this.searchForm).then((res)=>{
                    console.log(res)
                  const userInfo = localStorage.getItem('userInfo')
                  var departmentSn = JSON.parse(userInfo).departmentSn
                  var departmentName = JSON.parse(userInfo).departmentName
                    if (res.length > 0) {
                        this.types = [
                            {
                                key: departmentSn,
                                value: departmentSn,
                                title: departmentName,
                                children: this.convertToTreeData(res, departmentSn)
                            }
                        ];
                    }
                })
            },
            convertToTreeData(data, pid) {
                const result = [];
                let temp = [];
                for (let i = 0; i < data.length; i++) {
                    if (data[i].parentSn == pid) {
                        const obj = {
                            key: data[i].departmentSn,
                            value: data[i].departmentSn,
                            title: data[i].departmentName
                        };
                        temp = this.convertToTreeData(data, data[i].departmentSn);
                        if (temp.length > 0) {
                            obj["children"] = temp;
                        }
                        result.push(obj);
                    }
                }
                return result;
            },
            parentChange(value, label, extra) {
                this.parentSn = extra.node.dataRef.level
            },
            setFormValues(record) {
                Object.keys(record).forEach(key => {
                    this.form.getFieldDecorator(key);
                    let obj = {};
                    if(key == "departmentSn") {
                        this.departmentSn = record[key]
                    }else {
                        obj[key] = record[key];
                        this.form.setFieldsValue(obj);
                    }
                });
            },
            handleConfirm(e) {
                this.confirmLoading = true;
                this.form.validateFields(async (err, values) => {
                    this.confirmLoading = false;
                    if(!err) {
                        console.log(values)
                        if (!this.departmentSn) {
                            add(values).then((res) => {
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }else {
                            values.departmentSn = this.departmentSn
                            update(values).then((res) => {
                                this.departmentSn = ''
                                this.form.resetFields()
                                this.$message.success('操作成功');
                                this.$emit('confirm')
                                this.$emit('close')
                            })
                        }
                    }
                });
            },
            handleCancel() {
                this.$emit('close')
                this.departmentSn=''
                this.form.resetFields()
            }
        }
    }
</script>

<style scoped>

</style>